import './App.css';
import React from 'react';
import {MapContainer, Polyline, Popup, TileLayer, useMapEvents} from "react-leaflet";
import Burger from "./Burger";
import BurgerMenue from "./BurgerMenue";

function MyComponent() {
    const map = useMapEvents({
        click: (event) => {
            //  map.locate()
        },
        locationfound: (location) => {
            console.log('location found:', location)
        },
    })
    return null
}


class App extends React.Component {

    constructor() {
        super();
        this.state = {
            positions: [],
            burgerenabled: false,
        }
    }

    downloadTxtFile = async (position) => {
        const response = await fetch("https://rapid-water-79ba.tk7-dev.workers.dev/" + position.path);
        const body = await response.json();
        const element = document.createElement("a");
        const file = new Blob([body.gpxvalue], {
            type: "text/plain"
        });
        element.href = URL.createObjectURL(file);
        element.download = "myFile.txt";
        document.body.appendChild(element);
        element.click();
    };

    async componentDidMount() {
        const response = await fetch("https://rapid-water-79ba.tk7-dev.workers.dev/api");
        let positions = await response.json();
        this.setState({positions: positions.response});
    }

    handleBurger = () => {
        console.log("sd")
        this.setState({
            burgerenabled: !this.state.burgerenabled,
        })
    }

    render() {
        if (!this.state.positions[0]) {
            return (<div>Leer</div>)
        }
        return (
            <div>
                <div className="logo-wrap">
                    <svg height="300" width="250">

                        <circle cx="60" cy="200" r="40" stroke="#222222" fill="#53f6c7" stroke-width="7"/>
                        <circle cx="170" cy="200" r="40" stroke="#222222" fill="#53f6c7" stroke-width="7"/>
                        <path d="M100 190 A100 90 1 0 1 130 190" stroke="#222222" stroke-width="7"/>

                        <circle cx="70" cy="200" r="12" stroke="#000000" fill="#222222" stroke-width="10"/>
                        <circle cx="160" cy="200" r="12" stroke="#000000" fill="#222222" stroke-width="10"/>
                        <circle cx="61" cy="195" r="8" stroke="#000000" fill="#53f6c7" stroke-width="0"/>
                        <circle cx="149" cy="195" r="8" stroke="#000000" fill="#53f6c7" stroke-width="0"/>

                        <path d="M100 280 A100 90 1 0 0 160 270" stroke="#EBEAEC" stroke-width="8"/>


                        <path d="M20 200 A400 270 1 0 1 0 100
                              A400 270 1 0 1 30 120
                              A400 270 1 0 1 20 40
                              A400 270 1 0 0 70 80
                              A200 120 1 0 1 75 0
                              A120 120 1 0 0 140 30
                              A70 110 1 0 1 200 100
                              A70 110 1 0 0 225 60
                              A70 80 1 0 1 215 150
                              A190 190 1 0 0 240 130
                              A100 80 1 0 1 210 200
                              A50 60 1 0 0 160 140
                              A50 60 1 0 1 130 160
                              A110 140 1 0 0 140 140
                              A110 100 1 0 1 60 140
                              A50 200 1 0 0 20 200
                          "
                              stroke="#222222" fill="#222222" stroke-width="5"/>

                    </svg>
                </div>
                <header>
                    <div className="header_headline">
                        <div className="ausflug_text">AUSFLUG</div>
                        <span>MAP</span>
                    </div>
                    <Burger handleBurger={this.handleBurger}></Burger>
                </header>
                <hr/>
                <div className="content">
                    <MapContainer
                        // for simplicty set center to first gpx point
                        center={this.state.positions[0].values[0]}
                        className="map_container"
                        zoom={11}
                        scrollWheelZoom={true}
                    >
                        <MyComponent/>
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                        {
                            this.state.positions.map((position, key) => {
                                return <Polyline
                                    key={key}
                                    eventHandlers={{
                                        click: () => {
                                            console.log('marker clicked')
                                        },
                                    }}
                                    pathOptions={{
                                        fillColor: 'red',
                                        color: position.type === 'wandern' ? 'blue' : 'red'
                                    }}
                                    positions={position.values}
                                >
                                    <Popup>
                                        <button onClick={() => this.downloadTxtFile(position)}>Download GPX Datei
                                        </button>

                                        <a href={"https://rapid-water-79ba.tk7-dev.workers.dev/" + position.path}
                                           target="_blank" rel="noreferrer">Download GPX Datei</a>
                                    </Popup>
                                </Polyline>
                            })
                        }
                    </MapContainer>
                    {this.state.burgerenabled && <BurgerMenue></BurgerMenue>}
                </div>
            </div>
        );
    }
}

export default App;
