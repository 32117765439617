import './BurgerMenue.css';
import React from 'react';


class BurgerMenue extends React.Component {

    constructor() {
        super();
        this.state = {
            positions: [],
        }
    }

    async componentDidMount() {
    }

    render() {
        return (
            <div className="burger_menue">

            </div>
        );
    }
}

export default BurgerMenue;
