import './Burger.css';
import React from 'react';


class Burger extends React.Component {

    render() {
        return (
            <div className="burger">
                <input id="toggle" type="checkbox" onChange={() => this.props.handleBurger()}/>
                <label htmlFor="toggle" className="hamburger">
                    <div className="top-bun"/>
                    <div className="meat"/>
                    <div className="bottom-bun"/>
                </label>
            </div>
        );
    }
}

export default Burger;
